/* needed for menu on mobile not to flash open first */

(function () {
    var head = document.getElementsByTagName("head")[0];
    if (head) {
        var scriptStyles = document.createElement("link");
        scriptStyles.rel = "stylesheet";
        scriptStyles.type = "text/css";
        scriptStyles.href = "/css/script-styles.css";
        head.appendChild(scriptStyles);
    }
} ());